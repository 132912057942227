import React from "react";
import { Link } from "gatsby";
import TimerIcon from "@material-ui/icons/Timer";
import Author from "../components/Author";
import Date from "../components/Date";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import _ from "lodash";
export default function PostList(props) {
  return (
    <>
      {props.data.map(({ node }) => {
        const data = node.data;
        const title = data.title;
        const excerpt = data.postMarkdown.childMarkdownRemark.excerpt;
        const date = data.date;
        const authorName = data.author.map(({ data }) => (
          <Box display="inline" key={data.name}>
            {data.name}
          </Box>
        ));
        const timeToRead =
          node.data.postMarkdown.childMarkdownRemark.timeToRead;
        const slug = node.data.slug;
        const category = data.category;
        const translitedCategory = cyrillicToTranslit().transform(
          _.kebabCase(category)
        );
        return (
          <Box mb={2} key={title}>
            <Link style={{ textDecoration: "none" }} to={`/${slug}`}>
              <Typography variant="h4">{title}</Typography>
            </Link>
            {excerpt}

            <br></br>

            <Typography variant="caption">
              <Box mr={1} display="inline">
                <Date date={date} />
              </Box>

              <Author name={authorName} />
              <Box mr={1} display="inline">
                <TimerIcon />
                {`${timeToRead}min`}
              </Box>
              <Link to={`/categories/${translitedCategory}`}>{category}</Link>
            </Typography>
          </Box>
        );
      })}
    </>
  );
}
